ion-item.action_list_item {
  margin: 10px 4px;
  --border-color: #f2f1f2;
  --border-width: 1px;
  --border-radius: 16px;
  border-radius: 16px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 4px;
}

ion-item.action_list_item:last-child {
  margin-bottom: 45px;
}

ion-item.action_list_item::part(native) {
  border-radius: 16px;
  height: 64px;
  --inner-border-width: 0;
}

.action_list_item > div {
  display: flex;
  width: 100%;
}

.action_list_item h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
}
.action_list_item ion-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.actions--item {
  display: flex;
}

.actions--image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background: #feefee;
  border-radius: 12px;
  margin-right: 10px;
}

.actions--image IonImg {
  width: 24px;
  height: 24px;
}

.actions--counter {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.actions--item .actions--counter ion-label {
  display: flex;
  margin-bottom: 4px;
}

.actions--counter ion-label h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #374151;
}

.actions--counter ion-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #374151;
}

.actions--item .action-arrow {
  width: 24px;
  margin-right: 5px;
}

.notes-icon .action-arrow {
  width: 24px;
}

.actions--item .counter {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #4d4d4f;
  width: 15px;
  height: 17px;
  background: rgba(58, 181, 75, 0.1);
  border-radius: 2px;
  padding: 0;
  margin: 4px 0 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.actions--item .counter.hide {
  display: none;
}

.action-btn {
  margin-top: 20px;
}

.fiche-list ion-img {
  margin-left: auto;
}

.list-card ion-card {
  margin-inline: 0px;
  margin: 0;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
}

.list-card ion-card-content {
  padding-inline-end: 0;
  padding: 16px 9px 16px;
  border-radius: 16px;
}

.list-card ion-card-content.selected {
  -webkit-box-shadow: inset 0px 0px 0px 2px rgba(100, 149, 237, 1);
  -moz-box-shadow: inset 0px 0px 0px 2px rgba(100, 149, 237, 1);
  box-shadow: inset 0px 0px 0px 2px rgba(100, 149, 237, 1);
}

.list-card ion-text {
  font-size: 12px;
  line-height: 20px;
  color: #374151;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}

/* .list-card .card-icon {
  text-align: right;
} */

.list-card .card-icon i {
  color: #ea8373;
}

.list-card .card-icon.green i {
  color: #3ab54b;
  opacity: 0.7;
}

.list-card .card-icon i:before {
  font-size: 40px;
}

.list-card .no-result {
  font-size: 15px;
  line-height: 20px;
  margin: 20px 0;
}

.list-category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
  padding: 4px 0 3px 16px;
  min-height: 50px;
}

.list-category.selected {
  border-radius: 5px;
  -webkit-box-shadow: inset 0px 0px 0px 2px rgba(100, 149, 237, 1);
  -moz-box-shadow: inset 0px 0px 0px 2px rgba(100, 149, 237, 1);
  box-shadow: inset 0px 0px 0px 2px rgba(100, 149, 237, 1);
}

.list-card-category > ion-grid:first-child {
  margin-top: 10px;
}

.tabs-content .list-card-category > ion-grid:first-child {
  margin-top: 0;
}

.list-category:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  border-left: 6px solid #8db2a7;
  border-radius: 4px 0 0 4px;
}

.list-category .title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}

.list-category .desc {
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.filter-orange {
  filter: invert(82%) sepia(13%) saturate(7038%) hue-rotate(309deg)
    brightness(94%) contrast(95%);
}

.filter-green {
  filter: invert(61%) sepia(9%) saturate(7212%) hue-rotate(82deg)
    brightness(101%) contrast(61%);
}

.icon-edit {
  position: absolute;
  right: 0;
}
